var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "logisticsBusiness",
          align: "center",
          "min-width": "120",
          label: "集配中心",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "storeLogistics", align: "center", label: "城市仓" },
      }),
      _c("el-table-column", {
        attrs: { prop: "type", align: "center", label: "类型" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "beforeTonnage",
          align: "center",
          label: "变更前吨位",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "afterTonnage",
          align: "center",
          label: "变更后吨位",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "changeTime", align: "center", label: "变更时间" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }