<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>
      <div class="form-item">
        <el-select
          v-model="formData.changeType"
          clearable
          placeholder="请选择类型"
        >
          <el-option
            v-for="item of typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="form-item">
        <el-select
          placeholder="选择集配中心"
          v-model="formData.businessId"
          clearable
        >
          <el-option
            v-for="item of logisticsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="form-item jn-input-style">
        <el-select
          placeholder="选择城市仓"
          v-model="formData.logisticsId"
          :loading="loadingCityStore"
          :filter-method="(val) => onFilterMethod('cityStore', val)"
          @visible-change="(val) => onChangeVisible('cityStore', val)"
          filterable
          clearable
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="">
        <el-date-picker
          @change="payChange"
          v-model="pay_at"
          type="datetimerange"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="—"
          start-placeholder="变更时间起"
          end-placeholder="变更时间止"
        >
        </el-date-picker>
      </div>
      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search">
          查询
        </el-button>
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list v-loading="loadingData" :tableData="tableData"></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->
  </section>
</template>
<script>
import { TYPE_ENUM } from "./utils/enum/index.js";
import { postLogisticsTonnageChangeList } from "@/api/general/operation-center/index.js";
import TableList from "./modules/table-list/index.vue";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { formatDate } from "@/utils/tools/date.js";
import { getLogisticsTonnageChangeExport } from "@/api/export/index.js";
import { downLoadFlowBlob } from "@/utils/tools/base";

export default {
  name: "warehouse-tonnage",
  components: { TableList },
  data() {
    return {
      disabledExport: false, // 导出禁用
      typeList: Object.values(TYPE_ENUM),
      loadingCityStore: false, // 城市仓加载loading
      cityStoreList: [],
      total: 0,
      pay_at: [],
      loadingData: false,
      formData: {
        businessId: "",
        logisticsId: "",
        page: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
        // sign: "xiaojianjian",
        // timestamp: 1,
      },
      formDataClone: {}, // 复制数据，用于初始化
      tableData: [], //列表数据
      logisticsList: [], // 集配中心列表
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.setDefaultDateRange();
    this.initData();
  },
  methods: {
    /**
     * 变更时间 默认为前一天13:00 到今天13:00
     */
    setDefaultDateRange() {
      const now = new Date();
      const yesterday = new Date(now - 24 * 60 * 60 * 1000);
      this.pay_at = [
        new Date(
          yesterday.getFullYear(),
          yesterday.getMonth(),
          yesterday.getDate(),
          13,
          0,
          0
        ),
        new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0, 0),
      ];
      this.formData.startTime = formatDate(
        new Date(
          yesterday.getFullYear(),
          yesterday.getMonth(),
          yesterday.getDate(),
          13,
          0,
          0
        ),
        "yyyy-MM-dd HH:mm:ss"
      );
      this.formData.endTime = formatDate(
        new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0, 0),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    ajaxGetCityStoreList: debounce(async function (data) {
      this.loadingCityStore = true;
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
        // sign: "xiaojianjian",
        // timestamp: 1,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        this.loadingCityStore = false;
      }
    }, 500),
    /**
     * 搜索数据项
     */
    onFilterMethod(type, data) {
      if (type === "cityStore") {
        this.ajaxGetCityStoreList(data);
      }
    },
    /**
     * 失焦时，初始化下拉框
     */
    onChangeVisible(type, data) {
      if (!data && type === "cityStore") {
        this.ajaxGetCityStoreList();
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.postAjaxLogisticsTonnageChangeList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.postAjaxLogisticsTonnageChangeList();
    },
    /**
     * 查询
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.postAjaxLogisticsTonnageChangeList();
    },
    /**
     * 初始化数据
     */
    initData() {
      this.postAjaxLogisticsTonnageChangeList();
      this.ajaxGetCityStoreList();
      this.getAjaxLogisticsList();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.setDefaultDateRange();
      this.postAjaxLogisticsTonnageChangeList();
    },
    /**
     * 时间发生改变
     */
    payChange(val) {
      if (val) {
        this.formData.startTime = val[0];
        this.formData.endTime = val[1];
      } else {
        this.formData.startTime = "";
        this.formData.endTime = "";
      }
    },
    /**
     * 获得列表数据
     */
    async postAjaxLogisticsTonnageChangeList() {
      this.loadingData = true;
      try {
        const { data } = await postLogisticsTonnageChangeList(this.formData);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.error("ajax postOwnerMemberList err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /** 导出 */
    async onHandleExport() {
      this.disabledExport = true;
      this.$message.success("正在导出，请耐心等待");
      try {
        const params = {
          ...this.formData,
        };
        delete params.page;
        delete params.pageSize;
        const res = await getLogisticsTonnageChangeExport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax getLogisticsTonnageChangeExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .headSearch {
    flex: 1;
    justify-content: end;
    gap: 15px;
  }
}
</style>
