/** 类型枚举 */
export const TYPE_ENUM = {
  1: {
    value: 1,
    label: "变更吨位",
  },
  3: {
    value: 3,
    label: "初始吨位",
  },
  4: {
    value: 4,
    label: "零担吨位",
  },
  5: {
    value: 5,
    label: "吨位不足",
  },
};
