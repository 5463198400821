<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column
      prop="logisticsBusiness"
      align="center"
      min-width="120"
      label="集配中心"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="storeLogistics" align="center" label="城市仓">
    </el-table-column>
    <el-table-column prop="type" align="center" label="类型"> </el-table-column>
    <el-table-column
      prop="beforeTonnage"
      align="center"
      label="变更前吨位"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="afterTonnage"
      align="center"
      label="变更后吨位"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="changeTime" align="center" label="变更时间">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TableList",
  props: ["tableData"],
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
